import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { history } from '../sharedHistory';
import DROOLS from 'drools';
import DROOLS_pckg from '../../node_modules/drools/package.json';

import authentication from './authenticationReducer';
import config from './configReducer';
import language from './languageReducer'
import props from "./propReducer";
import pages from "./pagesReducer";

const DROOLS_reduxPerInstance = DROOLS_pckg['app-framework'] && DROOLS_pckg['app-framework'].reduxPerInstance;

const identityReducer = (state = null, action) => state;

const systemReducers = {
  router: connectRouter(history),
  authentication,
  pages,
  language,
  config,
  props
};

const singleReducer = (reduxPerInstance, reducer) => {
  if(!reducer) return null;
  if(reduxPerInstance) return null;
  return reducer;
};


const perInstanceReducer = (pckg, instanceId, reduxPerInstance, reducer) => {
  if (!reducer) return null;
  if (!reduxPerInstance) return null;
  const changedInstanceId = instanceId.replace(/-/g, '_');
  return (state, action) => {
    if (action.instanceId) {
      if (
        Array.isArray(action.instanceId) &&
        action.instanceId.includes(changedInstanceId)
      ) {
        return reducer(state, action);
      } else if (action.instanceId === changedInstanceId) {
        return reducer(state, action);
      } else {
        return state;
      }
    } else return reducer(state, action, action.instanceId, changedInstanceId);
  };
};


const singleReducersInit = {
  'drools': singleReducer(DROOLS_reduxPerInstance, DROOLS.reducer),
};

const userReducersPerInstanceInit = {
  'drools': DROOLS.reducer && DROOLS_reduxPerInstance && combineReducers(Object.fromEntries(Object.entries({
    '02623502_bc35_4856_9058_b5cba6ad56dd': perInstanceReducer('drools', '02623502-bc35-4856-9058-b5cba6ad56dd', DROOLS_pckg['app-framework'], DROOLS.reducer),
  }).filter(pair => pair[1]))),
};

const userReducersSingle = Object.fromEntries(Object.entries(singleReducersInit).filter(pair => pair[1]));
const userReducersPerInstance = Object.fromEntries(Object.entries(userReducersPerInstanceInit).filter(pair => pair[1]));

export default combineReducers({
  ...systemReducers,
  ...userReducersSingle,
  ...userReducersPerInstance,
});
